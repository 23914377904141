<template>
    <Teleport to="body">
        <div class="trust-modal">
            <div class="trust-modal__overlay" @click="() => emit('close')" />
            <div class="trust-modal__wrapper">
                <button class="trust-modal__close" @click="() => emit('close')">
                    <NuxtIcon name="ri:close-line" />
                </button>
                <div class="trust-modal__header">
                    <h5 v-if="content.Title" class="trust-modal__title">{{ content.Title }}</h5>
                </div>
                <div v-if="content.PaymentsList?.length" class="trust-modal__content">
                    <template v-for="payment in content.PaymentsList" :key="payment._uid">
                        <h3 v-if="payment.Title">{{ payment.Title }}</h3>
                        <div class="payment-info-modal-card-row">
                            <ul v-if="payment.Images?.length" class="new-card-row">
                                <li v-for="image in payment.Images" :key="image._uid">
                                    <NuxtImg
                                        v-if="image?.image?.filename"
                                        :src="image.image.filename"
                                        :title="image.image.title"
                                        :alt="image.image.alt"
                                        format="webp"
                                        loading="lazy"
                                        style="vertical-align: middle"
                                    />
                                </li>
                            </ul>
                        </div>
                        <p v-if="payment.Subtitle">{{ payment.Subtitle }}</p>
                    </template>
                </div>
                <div class="trust-modal__logos">
                    <img src="~/assets/images/securecards.png" loading="lazy" />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import type { IL4ModalContent } from '~/types/components/L4Links';

const { t, rt, locale } = useI18n();

const { content } = defineProps<{ content: IL4ModalContent }>();

// For index page reassign locale to 'en'
const { fullPath } = useRoute();
if (fullPath === '/') locale.value = 'en';
const emit = defineEmits<{ (e: 'close'): void }>();
</script>

<style lang="postcss" scoped>
.trust-modal {
    @apply fixed z-[100] w-full h-full left-0 top-0 overflow-y-auto;
    &__overlay {
        @apply fixed z-0 w-full h-full left-0 top-0 bg-[#969097] bg-opacity-70;
    }
    &__wrapper {
        @apply relative z-10 bg-white pb-2.5 my-8 tablet:my-20 mx-1.5 sm:mx-auto min-w-[306px] max-w-[676px];
        border: 2px solid #4b1862;
        @apply rounded-md overflow-hidden;
    }
    &__header {
        @apply bg-primary pt-2.5 pb-3.5 px-7 relative;
    }
    &__title {
        @apply text-lg tablet:text-[22px] text-white font-bold leading-5 tablet:leading-7 pl-20 pr-16 relative;
        &:before {
            content: '';
            @apply top-1 left-0 absolute w-[45px] h-[52px];
            background: url('~/assets/images/sprites/sprite.png') no-repeat -220px -385px;
        }
    }
    &__content {
        @apply pt-[15px] pb-0 px-[32px] text-primary text-base;
        .new-card-row {
            @apply mx-0 my-[1em] pl-10 flex relative;
            &:before {
                content: '';
                width: 5px;
                height: 5px;
                @apply bg-primary rounded-2xl absolute top-[50%] translate-y-[-50%] left-[20px];
            }
        }
        h3 {
            @apply text-lg leading-5 font-bold;
        }
        p {
            @apply text-base leading-[19px] mt-0 mb-5 mx-0;
        }
    }
    &__logos {
        @apply px-7;
    }
    &__close {
        @apply z-10 absolute border-none top-2 right-2 overflow-hidden;

        .iconify {
            @apply w-[31px] h-[31px] inline-block bg-white;
        }
    }
}
</style>
